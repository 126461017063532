import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import shortenUrl from "./shortenUrl.js"

const Lightbox = ({ url }) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [shareUrl, setShareUrl] = useState(url);
  // eslint-disable-next-line
  const [status, setStatus] = useState('waiting');

  const handleClick = () => {

    // setStatus('fetching')
    //
    // shortenUrl(url, (res, err) => {
    //   if (res.data) {
    //     setShareUrl(res.data.link)
    //     setStatus('success')
    //     setOpen(true)
    //   } else {
    //     setStatus('error')
    //     setOpen(true)
    //   }
    // }, () => {
    //   setStatus('error')
    //   setOpen(true)
    // })

    // Raw
    // --------------
    setShareUrl(url)
    setOpen(true)
  }

  const onCopyClick = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }

  return (
    <div className={`lightbox-actions__share ${open ? "open" : ""}`}>
      <button className="button--link" onClick={handleClick}>Share Link</button>
      <div className="lightbox-actions__link">
        {status !== 'error' ? (
          <CopyToClipboard
            text={shareUrl}
            onCopy={onCopyClick}
          >
            <button className="button--link">
              {copied ? "Copied!" : shareUrl }
            </button>
          </CopyToClipboard>
        ) : (
          <button className="button--link">Not available</button>
        )}
      </div>
    </div>
  );
};

export default Lightbox;
