import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
// ---------------------------------------------
import Header from "../components/header";
import Layout from "../components/layout";
import Transition, { Link } from "../components/transition";
import { LightboxContext } from "../components/lightbox/store";
// import shortenUrl from "../components/lightbox/shortenUrl";
import Share from "../components/lightbox/share";
import Download from "../components/lightbox/download";
import Picture from "../components/picture";
import Vimeo from "../components/vimeo";
import Slideshow from "../components/slideshow";
import { CloseIcon } from "../components/svgs"
import SEO from "../components/seo";

const Lightbox = ({ handleLink, craft, isDownloading, setDownloading }) => {

  // Data gathered from localStorage or URL hash
  const [lightbox, setLightbox] = useContext(LightboxContext);

  // Content sorting
  const { footerText, pdfIntro, pdfOutro } = craft.lightbox[0];
  const footerTextRows = footerText.split("\n");
  const [gallery, setGallery] = useState([])
  const [url, setUrl] = useState(null);

  // Slideshow functionality
  const [showSlideshow, setShowSlideshow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slides, setSlides] = useState([]);
	const artist = slides[activeIndex] && slides[activeIndex].artist;

  const clear = () => {
    //eslint-disable-next-line
    const confirmation = confirm("Are you sure?");
    if (confirmation) {
      setLightbox([]);
    }
  };

  const getUrl = () => {
    const transform = gallery.map(e => [e[0].id, e[1].id]);
    const query = JSON.stringify(transform)
    const encodedQuery = btoa(query)
    return `${window.location.origin}${window.location.pathname}/#${encodedQuery}`;
  };

  const handleMailUrl = () => {
    // shortenUrl(url, (res, err) => {
    //   if (res.data) {
    //     const emailSubject = "Custom Lightbox from MINKMGMT";
    //     const emailBody = `%0D%0A%0D%0A${res.data.link}`;
    //     window.open(`mailto:?subject=${emailSubject}&body=${emailBody}`);
    //   } else {
    //     console.log('error')
    //   }
    // }, () => {
    //   console.log('error')
    // })

    // Raw...
    // ------
    const emailSubject = "Custom Lightbox from MINKMGMT";
    const emailBody = `%0D%0A%0D%0A${url}`;
    window.open(`mailto:?subject=${emailSubject}&body=${emailBody}`);
  }

  useEffect(() => {
    setGallery(
      lightbox.map(item => [
        craft.assets.find(asset => asset.id === item.id),
        craft.projects.find(project => project.id === item.projectId)
      ])
    )
  }, [lightbox])

  useEffect(() => {
    setUrl(getUrl());

    // Create slides for <Slideshow />
    if (gallery.length > 0) {
      setSlides(
        gallery.map(([asset, project]) => {
          const artist = craft.artists ? craft.artists.find(f => f.authorId === project.authorId) : null
          return {
            ...asset,
            project: project,
            artist: artist
          }
        })
      )
    } else {
      setShowSlideshow(false)
    }
  }, [gallery])

	const namespace = artist ? `/project/${slides[activeIndex].artist.slug}` : `/project`;

  return (
    <div>
      {showSlideshow && slides[activeIndex] && (
        <div className="lightbox-gallery">
          <div className="gallery-title">
            <Link to={`${namespace}/${slides[activeIndex].project.slug}-${slides[activeIndex].project.id}`} handleLink={handleLink}>
              <span className="title uppercase">{slides[activeIndex].project.title}</span>
							{artist && (<i>{` by `}</i>)}
              {artist && (<span className="uppercase">{artist.title}</span>)}
            </Link>
          </div>
          <Slideshow slides={slides} activeIndex={activeIndex} setActiveIndex={setActiveIndex} showTooltip={true} hasLightboxAdd />
          <button onClick={() => setShowSlideshow(false)} className="close-gallery">
            <CloseIcon colour={false ? '#FFFFFF' : '#000000'} />
          </button>
        </div>
      )}
      <div className="lightbox-actions">
        <Download
          artists={craft.artists}
          lightbox={gallery}
          pdfIntro={pdfIntro}
          pdfOutro={pdfOutro}
          isDownloading={isDownloading}
          setDownloading={setDownloading}
        />
        <button className="button--link" onClick={() => handleMailUrl()}>Email</button>
        <Share url={url} />
        <button className="button--link" onClick={() => clear()}>New Folio</button>
      </div>
      {gallery.length > 0 ? (
        <div className="big-thumbs">
          <div className="artist-thumbnails">
            {gallery.length > 0 && gallery.map(([asset, project], i) => (
              <div
                className={`thumb active`}
                onClick={() => {
                  setActiveIndex(i)
                  setShowSlideshow(true)
                }}
                key={`lightbox-thumb-${i}`}
              >
                {asset.vimeoUrl ? (
                  <Vimeo {...asset} className="proheight" />
                ) : (
                  <Picture className="proheight" {...asset} resize={{ height: 320 }} />
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="pabs fs flex-center">
          <center>
            <h4>Lightbox is empty</h4>
          </center>
        </div>
      )}
      <div className="lightbox-footer">
        {footerTextRows.map((text, i) =>
          <h4 className="no-margin" key={`footer-text-${i}`}>
            {text}
          </h4>
        )}
      </div>
    </div>

  )
}

const LightboxPage = ({ data, location }) => {

  const [isDownloading, setDownloading] = useState(false);

  return (
    <Layout>
      <SEO title="Lightbox" />
      <Transition
        location={location}
        render={handleLink => (
          <>
            <Header handleLink={handleLink} />
            <div className="lightbox-background" />
            {/* isDownloading */}
            <main className="lightbox" style={{
              cursor: false ? 'progress' : 'auto'
            }}>
              <Lightbox {...data} handleLink={handleLink} isDownloading={isDownloading} setDownloading={setDownloading} />
            </main>
          </>
        )}
      />
    </Layout>
  )
}

LightboxPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default LightboxPage;

export const pageQuery = graphql`
  query LightboxQuery {
    craft {
      lightbox: entries(type: "Lightbox") {
        ... on Craft_lightbox_lightbox_Entry {
          pdfIntro
          pdfOutro
          footerText
        }
      }
      assets {
        ... on Craft_minkAssets_Asset {
          ...Asset
        }
      }
      projects: entries(type: "Projects") {
        ... on Craft_projects_projects_Entry {
          ...ProjectGallery
        }
      }
      artists: entries(type: "Artists") {
        ... on Craft_artists_artists_Entry {
          id
          title
          slug
          authorId
        }
      }
    }
  }
`
