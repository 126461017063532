import React from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "./vfs_fonts";
import logo from "../../images/logo.png";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const host = 'https://mink.imgix.net/';

const LightboxDownload = ({ artists, lightbox, pdfIntro, pdfOutro, url, isDownloading, setDownloading }) => {

  const getDataUri = (photo, callback) => {
    const image = new Image();
    image.crossOrigin = "anonymous";

    image.onload = function() {
      const canvas = document.createElement("canvas");
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;
      canvas.getContext("2d").drawImage(this, 0, 0);
      callback(canvas.toDataURL());
    };

    image.src = photo.src;
  };

  const handleDownloadClick = e => {
    e.preventDefault();

    setDownloading(true);

    const introText = pdfIntro.split("/n");
    const footerText = pdfOutro.split("/n");
    const photos = lightbox;
    let renderedImages = 0;
    const images = [];

    lightbox.forEach(([asset, project], i) => {
      // const artist = asset[1].artist[0];
      const artist = artists ? artists.find(f => f.authorId === project.authorId) : null

      const src = `${host}${asset.path}?fit=crop&fm=jpg&w=auto&h=800&q=100`;

      const photo = artist ? {
        src: src,
        description: `${project.title.toUpperCase()} by ${artist.title.toUpperCase()}`,
        url: `minkmgmt.com/${artist.slug}`,
        index: i
      } : {
        src: src,
        description: `${project.title.toUpperCase()}`,
        url: `minkmgmt.com`,
        index: i
      };

      getDataUri(photo, dataUri => {
        images.push([
          {
            image: dataUri,
            margin: [0, 10],
            fit: [515, 420],
            style: "image",
            index: i
          }, {
            text: photo.description,
            style: "description",
            margin: [0, 5]
          }, {
            text: photo.url,
            link: `https://${photo.url}`,
            style: "description",
            margin: [0, 5]
          }
        ]);

        // If last image, download pdf
        renderedImages++;
        if (renderedImages === photos.length) {
          images.sort((a, b) => {
            return a[0].index - b[0].index;
          });
          exportPDF();

        }
      });
    });

    const exportPDF = () => {
      const sortedImages = images.sort((a, b) => a.id - b.id);

      pdfMake.fonts = {
        GTSuperDisplayLight: {
          normal: "GT-Super-Display_Light.woff",
          bold: "GT-Super-Display_Light.woff",
          italics: "GT-Super-Display_Light.woff",
          bolditalics: "GT-Super-Display_Light.woff"
        }
      };

      const render = logoDataUri => {
        const docDefinition = {
          pageOrientation: "landscape",
          defaultStyle: {
            font: "GTSuperDisplayLight"
          },
          styles: {
            image: {
              alignment: "center",
              margin: [0, 25, 0, 10],
              fit: [700, 300]
            },
            description: {
              alignment: "center"
            }
          },
          footer: function(currentPage, pageCount, pageSize) {
            return (currentPage > 1 && currentPage < pageCount) ? [
              {
                text: "© Copyright. All rights reserved.",
                alignment: "center",
                fontSize: 8,
              }
            ] : false;
          },
          content: [
            { image: logoDataUri, width: 360, margin: [200, 200, 200, 0] },
            { stack: introText, fontSize: 13, lineHeight: 1.2, alignment: "center", margin: [ 100, 200, 100, 0 ] },
            { stack: sortedImages },
            { text: " ", margin: [ 0, 200, 0, 0 ] },
            { stack: footerText, fontSize: 13, lineHeight: 1.2, alignment: "center", margin: [ 100, 240, 100, 0 ] }
          ]
        };

        const filename = `${Date.now()}-minkmgmt.com-lightbox.pdf`;
        pdfMake.createPdf(docDefinition).download(filename);

        setDownloading(false);
      };

      // Get logo and then render pdf
      getDataUri({ src: logo, type: "image/png" }, render);
    };
  };

  return (
    <div className={"lightbox-actions__share"} style={{
      cursor: isDownloading ? 'progress' : 'auto'
    }}>
      <button className="button--link" onClick={handleDownloadClick}>{isDownloading ? `Downloading PDF...` : 'Download'}</button>
    </div>
  );
};

export default LightboxDownload;
